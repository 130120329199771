.oka{
  max-height: 400px;
}

.buttonwrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
}

.buttonwrapper > button{
  position: absolute;
  top: 15px;
  right: 10px;
}

.bucket{
  max-height: 67vh;
  position: relative;
}

.play{
  max-height: 77vh;
}

.img{
  margin-right: 2%;
}

.login{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.ulstyle{
  margin-left: 2%;
}

.tp{
  font-size: 14px !important;
}

.euiTab__content{
  font-size: 14px !important;
}

.euiTab{
  height: 40px !important;
}

.red{
  color: red !important;
}

.results{
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #1BA9F5;
}


.audio{
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}


.euiTable.euiTable--responsive .euiTableRowCell{
  min-width: 30% !important;
}

.image-container{
  position: relative;
  width: 100%;
  text-align: center;
  max-height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
  
}

.image{
  width: 80%;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  
}

.imageShower{
  position: fixed;
    z-index: 1;
    left: 0;
    background-color: rgba(101, 101, 101, 0.9);;
    height: 100%;
    top: 0;
}

.imagebig{
  width: 100%;
  margin-top: 30%;
}